<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>
      <h2>Thesaurus Editor</h2>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <!-- form -->
    <form [formGroup]="form" (submit)="save()">
      <div>
        <mat-form-field style="width: 350px">
          <mat-label>ID</mat-label>
          <input matInput [formControl]="id" />
          <mat-error
            *ngIf="$any(id).errors?.required && (id.dirty || id.touched)"
            >ID required</mat-error
          >
          <mat-error
            *ngIf="$any(id).errors?.maxLength && (id.dirty || id.touched)"
            >ID too long</mat-error
          >
          <mat-error
            *ngIf="$any(id).errors?.pattern && (id.dirty || id.touched)"
            >invalid ID</mat-error
          >
        </mat-form-field>
        &nbsp;
        <mat-checkbox [formControl]="alias">alias</mat-checkbox>
        &nbsp;
        <!-- lookup -->
        <cadmus-thesaurus-lookup
          *ngIf="alias?.value"
          style="display: inline-block"
          [initialValue]="targetId.value || undefined"
          [lookupFn]="lookupFn"
          label="target ID"
          (entryChange)="onTargetIdChange($event)"
        ></cadmus-thesaurus-lookup>
      </div>
      <div *ngIf="!alias.value">
        <!-- filter form -->
        <form [formGroup]="filterForm" (submit)="applyFilter()">
          <fieldset>
            <legend>filters</legend>
            <mat-form-field style="width: 6em">
              <mat-label>ID/value</mat-label>
              <input matInput [formControl]="idOrValue" />
            </mat-form-field>
            &nbsp;
            <mat-form-field
              *ngIf="parentIds$ | async as parentIds"
              style="width: 20em"
            >
              <mat-label>parent ID</mat-label>
              <mat-select [formControl]="parentId">
                <mat-option [value]="null">(any)</mat-option>
                <mat-option *ngFor="let e of parentIds" [value]="e.id">{{
                  e.value
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <button
              type="submit"
              mat-icon-button
              color="primary"
              matTooltip="Apply filters"
            >
              <mat-icon>check_circle</mat-icon>
            </button>
            <button
              type="button"
              mat-icon-button
              (click)="expandAll()"
              matTooltip="Expand all"
            >
              <mat-icon>unfold_more</mat-icon>
            </button>
            <button
              type="button"
              mat-icon-button
              (click)="collapseAll()"
              matTooltip="Collapse all"
            >
              <mat-icon>unfold_less</mat-icon>
            </button>
          </fieldset>
        </form>

        <!-- list -->
        <div *ngIf="pagination$ | async as pagination">
          <cadmus-thesaurus-node
            *ngFor="let node of pagination.data"
            [node]="node"
            (nodeChange)="addNode($event)"
            (signal)="onSignal($event)"
          ></cadmus-thesaurus-node>
          <button
            type="button"
            mat-flat-button
            color="primary"
            (click)="appendNode()"
            *ngIf="pagination.total === 0"
            style="margin: 8px 0"
          >
            <mat-icon>add_circle</mat-icon> node
          </button>

          <!-- paginator -->
          <mat-paginator
            *ngIf="pagination.data?.length"
            [length]="pagination.total"
            [pageSize]="pagination.perPage"
            [pageSizeOptions]="[20, 50, 75, 100]"
            [pageIndex]="pagination.currentPage - 1"
            [showFirstLastButtons]="true"
            (page)="pageChange($event)"
          ></mat-paginator>
        </div>
      </div>
      <button type="button" color="warn" mat-stroked-button (click)="close()">
        <mat-icon>cancel</mat-icon>
        close
      </button>
      &nbsp;
      <button
        type="submit"
        color="primary"
        mat-stroked-button
        [disabled]="form.invalid"
      >
        <mat-icon>save</mat-icon>
        save
      </button>
    </form>
  </mat-card-content>
</mat-card>
