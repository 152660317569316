import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  Validators,
  AbstractControl,
  ValidationErrors,
  FormGroup,
  FormControl,
  FormBuilder,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import {
  AuthJwtAccountService,
  ExistResult,
  RegistrationModel,
} from '../../services/auth-jwt-account.service';
import { PasswordValidators } from '../../validators/password.validators';

@Component({
  selector: 'auth-jwt-registration',
  templateUrl: './auth-jwt-registration.component.html',
  styleUrls: ['./auth-jwt-registration.component.css'],
})
export class AuthJwtRegistrationComponent {
  public busy: boolean | undefined;
  // form
  public form: FormGroup;
  public email: FormControl<string>;
  public name: FormControl<string>;
  public firstName: FormControl<string>;
  public lastName: FormControl<string>;
  public password: FormControl<string>;
  public confirmPassword: FormControl<string>;

  /**
   * Emitted when a user was successfully registered. Usually
   * you should handle this to move away from the registration
   * page.
   */
  @Output()
  public registered: EventEmitter<any>;

  constructor(
    formBuilder: FormBuilder,
    private _snackbar: MatSnackBar,
    private _accountService: AuthJwtAccountService
  ) {
    this.registered = new EventEmitter<any>();
    // form
    this.email = formBuilder.control('', {
      validators: [Validators.required, Validators.email],
      asyncValidators: this.getUniqueEmailValidator(this._accountService).bind(
        this
      ),
      nonNullable: true,
    });

    this.name = formBuilder.control('', {
      validators: Validators.required,
      asyncValidators: this.getUniqueNameValidator(this._accountService).bind(
        this
      ),
      nonNullable: true,
    });

    this.firstName = formBuilder.control('', {
      validators: [Validators.required, Validators.maxLength(50)],
      nonNullable: true,
    });
    this.lastName = formBuilder.control('', {
      validators: [Validators.required, Validators.maxLength(50)],
      nonNullable: true,
    });

    // http://stackoverflow.com/questions/35474991/angular-2-form-validating-for-repeat-password
    this.password = formBuilder.control('', {
      validators: [Validators.required, PasswordValidators.standard()],
      nonNullable: true,
    });
    this.confirmPassword = formBuilder.control('', {
      validators: Validators.required,
      nonNullable: true,
    });

    this.form = formBuilder.group(
      {
        email: this.email,
        name: this.name,
        firstName: this.firstName,
        lastName: this.lastName,
        password: this.password,
        confirmPassword: this.confirmPassword,
      },
      {
        validators: [
          PasswordValidators.areEqual('password', 'confirmPassword'),
        ],
      }
    );
  }

  /**
   * Creates a unique name validator. There is no dependency injection at this level,
   * but we can use closures. As a matter of fact, we have access to the service instance
   * from the component where we register validators. So we will implement a function
   * that will accept the service as parameter, and create the actual validation function.
   * This function will have access to the service when called during the validation process.
   * See http://restlet.com/blog/2016/02/17/implementing-angular2-forms-beyond-basics-part-2/.
   */
  private getUniqueNameValidator(service: AuthJwtAccountService) {
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return new Promise((resolve, reject) => {
        // avoid checking if empty
        if (!control.value) {
          resolve(null);
        } else {
          service.isNameRegistered(control.value).subscribe({
            next: (data: ExistResult) => {
              if (!data.isExisting) {
                resolve(null);
              } else {
                resolve({ uniqueName: true });
              }
            },
            error: (error) => {
              console.error(
                'Unique name validator error' +
                  (error ? JSON.stringify(error) : '')
              );
              resolve({ uniqueName: true });
            },
          });
        }
      });
    };
  }

  private getUniqueEmailValidator(service: AuthJwtAccountService) {
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return new Promise((resolve, reject) => {
        // avoid checking if empty
        if (!control.value) {
          resolve(null);
        } else {
          service.isEmailRegistered(control.value).subscribe({
            next: (data: ExistResult) => {
              if (!data.isExisting) {
                resolve(null);
              } else {
                resolve({ uniqueEmail: true });
              }
            },
            error: (error) => {
              console.error(
                'Unique email validator error' +
                  (error ? JSON.stringify(error) : '')
              );
              resolve({ uniqueEmail: true });
            },
          });
        }
      });
    };
  }

  public onSubmit(): void {
    if (
      !this.form.valid ||
      this.busy ||
      this.name.pending ||
      this.email.pending
    ) {
      return;
    }

    const model: RegistrationModel = {
      email: this.email.value,
      name: this.name.value,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      password: this.password.value,
    };

    this.busy = true;
    this._accountService
      .register(model)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.busy = false;
          this._snackbar.open($localize`Registration succeeded`, 'OK');
          this.registered.emit();
        },
        error: (error) => {
          this.busy = false;
          console.error(
            'Registration  error' + (error ? JSON.stringify(error) : '')
          );
          this._snackbar.open($localize`Registration error`, 'OK');
        },
      });
  }
}
