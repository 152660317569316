<form [formGroup]="form">
  <div id="container">
    <!-- place -->
    <fieldset id="place">
      <legend>place</legend>
      <!-- tag (bound) -->
      <ng-container *ngIf="tagEntries">
        <mat-form-field>
          <mat-label>tag</mat-label>
          <mat-select [formControl]="plTag">
            <mat-option *ngFor="let e of tagEntries" [value]="e.id">{{
              e.value
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <!-- tag (free) -->
      <ng-container *ngIf="!tagEntries">
        <mat-form-field>
          <mat-label>tag</mat-label>
          <input matInput [formControl]="plTag" />
          <mat-error
            *ngIf="
              plTag.hasError('max-length') && (plTag.dirty || plTag.touched)
            "
            >tag too long</mat-error
          >
        </mat-form-field>
      </ng-container>
      &nbsp;
      <mat-form-field>
        <mat-label>place</mat-label>
        <input matInput [formControl]="place" />
        <mat-error
          *ngIf="place.hasError('max-length') && (place.dirty || place.touched)"
          >place too long</mat-error
        >
      </mat-form-field>
      <!-- assertion -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>place assertion</mat-expansion-panel-header>
        <cadmus-refs-assertion
          [assTagEntries]="assTagEntries"
          [refTypeEntries]="refTypeEntries"
          [refTagEntries]="refTagEntries"
          [assertion]="plAssertion.value"
          (assertionChange)="onPlAssertionChange($event)"
        >
        </cadmus-refs-assertion>
      </mat-expansion-panel>
    </fieldset>

    <!-- date -->
    <fieldset id="date">
      <legend>date</legend>
      <mat-checkbox [formControl]="hasDate">has date</mat-checkbox>
      &nbsp;
      <!-- tag (bound) -->
      <mat-form-field *ngIf="hasDate?.value && tagEntries?.length">
        <mat-label>tag</mat-label>
        <mat-select [formControl]="dtTag">
          <mat-option *ngFor="let e of tagEntries" [value]="e.id">{{
            e.value
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- tag (free) -->
      <mat-form-field *ngIf="hasDate?.value && !tagEntries?.length">
        <mat-label>tag</mat-label>
        <input matInput [formControl]="dtTag" />
        <mat-error
          *ngIf="dtTag.hasError('max-length') && (dtTag.dirty || dtTag.touched)"
          >tag too long</mat-error
        >
      </mat-form-field>
      <div>
        <cadmus-refs-historical-date
          *ngIf="hasDate?.value"
          [date]="date.value || undefined"
          (dateChange)="onDateChange($event)"
        ></cadmus-refs-historical-date>

        <mat-expansion-panel *ngIf="hasDate?.value">
          <!-- assertion -->
          <mat-expansion-panel-header
            >date assertion</mat-expansion-panel-header
          >
          <cadmus-refs-assertion
            [assTagEntries]="assTagEntries"
            [refTypeEntries]="refTypeEntries"
            [refTagEntries]="refTagEntries"
            [assertion]="dtAssertion.value"
            (assertionChange)="onDtAssertionChange($event)"
          >
          </cadmus-refs-assertion>
        </mat-expansion-panel>
      </div>
    </fieldset>
  </div>
</form>
