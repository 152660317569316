<mat-card>
  <mat-card-header>
    <mat-card-title>Images Source</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="form" (submit)="save()">
      <div>
        <mat-form-field>
          <mat-label>source</mat-label>
          <mat-select [formControl]="source">
            <mat-option *ngFor="let e of sources" [value]="e">{{
              e.id
            }}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              $any(source).errors?.required && (source.dirty || source.touched)
            "
            >source required</mat-error
          >
        </mat-form-field>
      </div>
      <div *ngIf="source.value" class="form-row">
        <mat-icon>link</mat-icon>
        <a [attr.href]="source.value.value" target="_blank">{{
          source.value.value
        }}</a>
      </div>
      <div class="form-row">
        <button
          type="button"
          color="warn"
          mat-icon-button
          matTooltip="Discard changes"
          (click)="cancel()"
        >
          <mat-icon>clear</mat-icon>
        </button>
        <button
          type="submit"
          color="primary"
          mat-flat-button
          matTooltip="Select source"
          [disabled]="form.invalid || form.pristine"
        >
          <mat-icon>check_circle</mat-icon>
          select
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
