<form [formGroup]="form" (submit)="save()">
  <mat-card appearance="outlined">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>textsms</mat-icon>
      </div>
      <mat-card-title
        >Quotations Fragment {{ data?.value?.location }}</mat-card-title
      >
      <mat-card-subtitle>
        {{ frText }}
      </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <mat-tab-group [(selectedIndex)]="currentTabIndex">
        <!-- quotations editor -->
        <mat-tab label="Quotations">
          <table *ngIf="entries.value?.length">
            <thead>
              <tr>
                <th></th>
                <th>tag</th>
                <th>author</th>
                <th>work</th>
                <th>citation</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let entry of entries.value;
                  let i = index;
                  let first = first;
                  let last = last
                "
              >
                <td>
                  <button
                    mat-icon-button
                    type="button"
                    matTooltip="Edit entry"
                    color="primary"
                    (click)="editEntry(entry, i)"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    type="button"
                    matTooltip="Move entry up"
                    [disabled]="first"
                    (click)="moveEntryUp(i)"
                  >
                    <mat-icon>arrow_upward</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    type="button"
                    matTooltip="Move entry down"
                    [disabled]="last"
                    (click)="moveEntryDown(i)"
                  >
                    <mat-icon>arrow_downward</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    type="button"
                    matTooltip="Remove this entry"
                    color="warn"
                    (click)="removeEntry(i)"
                  >
                    <mat-icon>remove_circle</mat-icon>
                  </button>
                </td>
                <td>{{ entry.tag }}</td>
                <td>{{ getNameFromId(entry.author) }}</td>
                <td>{{ getNameFromId(entry.work) }}</td>
                <td>{{ entry.citation }}</td>
              </tr>
            </tbody>
          </table>
          <button
            type="button"
            mat-flat-button
            (click)="addEntry()"
          >
            <mat-icon>add_circle</mat-icon> entry
          </button>
        </mat-tab>
        <!-- entry editor -->
        <mat-tab label="Entry" *ngIf="editedEntry">
          <cadmus-quotation-entry
            [entry]="editedEntry"
            [workDictionary]="workDictionary"
            [tagEntries]="tagEntries"
            (editorClose)="closeEntry()"
            (entryChange)="saveEntry($event)"
          ></cadmus-quotation-entry>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>

    <mat-card-actions>
      <cadmus-close-save-buttons
        [form]="form"
        [noSave]="userLevel < 2"
        (closeRequest)="close()"
      ></cadmus-close-save-buttons>
    </mat-card-actions>
  </mat-card>
</form>
