<div>
  <form role="form" [formGroup]="form" (submit)="onSubmit()">
    <fieldset>
      <legend i18n>registration</legend>
      <!-- email -->
      <div class="form-row">
        <mat-form-field>
          <mat-label i18n>email</mat-label>
          <input
            matInput
            type="email"
            id="email"
            maxlength="256"
            required
            autofocus
            spellcheck="false"
            [formControl]="email"
          />
          <mat-error
            i18n
            *ngIf="
              $any(email).errors?.required && (email.dirty || email.touched)
            "
            >email required</mat-error
          >
          <mat-error
            i18n
            *ngIf="$any(email).errors?.email && (email.dirty || email.touched)"
            >invalid email</mat-error
          >
          <mat-error
            i18n
            *ngIf="
              $any(email).errors?.uniqueEmail && (email.dirty || email.touched)
            "
            >email already registered</mat-error
          >
        </mat-form-field>
        <mat-icon *ngIf="email.pending">hourglass</mat-icon>
      </div>

      <!-- name -->
      <div class="form-row">
        <mat-form-field>
          <mat-label i18n>username</mat-label>
          <input
            matInput
            type="text"
            id="name"
            maxlength="50"
            required
            pattern="^[a-zA-Z][a-zA-Z0-9]{2,49}$"
            spellcheck="false"
            [formControl]="name"
          />
          <mat-error
            i18n
            *ngIf="$any(name).errors?.required && (name.dirty || name.touched)"
            >username required</mat-error
          >
          <mat-error
            i18n
            *ngIf="$any(name).errors?.pattern && (name.dirty || name.touched)"
            >invalid username</mat-error
          >
          <mat-error
            i18n
            *ngIf="
              $any(name).errors?.uniqueName && (name.dirty || name.touched)
            "
            >username already registered</mat-error
          >
        </mat-form-field>
        <mat-icon *ngIf="name.pending">hourglass</mat-icon>
      </div>

      <!-- first name -->
      <div>
        <mat-form-field>
          <mat-label i18n>first name</mat-label>
          <input
            matInput
            type="text"
            id="firstName"
            maxlength="50"
            required
            spellcheck="false"
            [formControl]="firstName"
          />
          <mat-error
            i18n
            *ngIf="
              firstName.hasError('required') &&
              (firstName.dirty || firstName.touched)
            "
            class="text-danger small"
          >
            first name required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- last name -->
      <div>
        <mat-form-field>
          <mat-label i18n>last name</mat-label>
          <input
            matInput
            type="text"
            id="lastName"
            maxlength="50"
            required
            spellcheck="false"
            [formControl]="lastName"
          />
          <mat-error
            i18n
            *ngIf="
              lastName.hasError('required') &&
              (lastName.dirty || lastName.touched)
            "
            class="text-danger small"
          >
            last name required
          </mat-error>
        </mat-form-field>
      </div>

      <!-- <div [formGroup]="passwords"> -->
      <!-- password -->
      <div>
        <mat-form-field>
          <mat-label i18n>password</mat-label>
          <input
            matInput
            type="password"
            name="password"
            autocomplete="new-password"
            maxlength="50"
            required
            spellcheck="false"
            [formControl]="password"
          />
          <auth-jwt-password-strength-bar [passwordToCheck]="password.value">
          </auth-jwt-password-strength-bar>
          <mat-error
            i18n
            *ngIf="
              $any(password).errors?.required &&
              (password.dirty || password.touched)
            "
            >password required</mat-error
          >
          <mat-error
            i18n
            *ngIf="
              $any(password).errors?.passwordtooshort &&
              (password.dirty || password.touched)
            "
            >at least 8 characters</mat-error
          >
          <mat-error
            i18n
            *ngIf="
              $any(password).errors?.noupperinpassword &&
              (password.dirty || password.touched)
            "
            >at least 1 uppercase letter</mat-error
          >
          <mat-error
            i18n
            *ngIf="
              $any(password).errors?.nolowerinpassword &&
              (password.dirty || password.touched)
            "
            >at least 1 lowercase letter</mat-error
          >
          <mat-error
            i18n
            *ngIf="
              $any(password).errors?.nosymbolinpassword &&
              (password.dirty || password.touched)
            "
            >at least 1 punctuation/symbol</mat-error
          >
        </mat-form-field>
      </div>

      <!-- confirm password -->
      <div>
        <mat-form-field>
          <mat-label i18n>confirm password</mat-label>
          <input
            matInput
            type="password"
            name="confirmPassword"
            maxlength="50"
            required
            spellcheck="false"
            [formControl]="confirmPassword"
          />
        </mat-form-field>
      </div>
      <div
        i18n
        *ngIf="form.errors?.areequal && confirmPassword.touched"
        style="color: red"
      >
        password differs from confirmation password
      </div>

      <button i18n
        mat-raised-button
        type="submit"
        color="primary"
        [disabled]="
          form.invalid || form.pristine || busy || name.pending || email.pending
        "
      >
        register
      </button>
      <mat-progress-spinner diameter="20" *ngIf="busy"></mat-progress-spinner>
    </fieldset>
  </form>

  <details>
    <summary i18n>Hints</summary>
    <p i18n>
      To register a new user, you must provide his email address, choose a
      password, and choose a username, which must be unique (you will be
      notified if another user has already taken that name). The username must
      include only letters/digits, start with a letter, and be no shorter than 3
      characters, nor longer than 50.
    </p>
    <p i18n>
      To promote a decent security level, the password must include at least 8
      characters, uppercase and lowercase letters, digits, and punctuation (like
      dashes, stops, parentheses, etc.).
    </p>
    <p i18n>
      Once registered, if messaging is enabled on the server side the user will
      receive an email message to the email address you specified; he will have
      to click on the provided link to complete the registration process.
      Otherwise, you should edit the newly created user and confirm the email
      registration.
    </p>
  </details>
</div>
