<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title> Thesauri </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div id="container">
      <!-- filters -->
      <div id="filters">
        <cadmus-thesaurus-filter></cadmus-thesaurus-filter>
      </div>

      <!-- list -->
      <div id="list" *ngIf="pagination$ | async as pagination">
        <div *ngIf="(status$ | async)?.value === 'pending'">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <table>
          <thead>
            <th></th>
            <th>id</th>
            <th>entries</th>
          </thead>
          <tbody>
            <tr *ngFor="let thesaurus of pagination.data">
              <td class="fit-width">
                <button
                  mat-icon-button
                  type="button"
                  matTooltip="Edit {{ thesaurus.id }}"
                  color="primary"
                  (click)="editThesaurus(thesaurus)"
                >
                  <mat-icon>mode_edit</mat-icon>
                </button>
                <button
                  *ngIf="userLevel > 2"
                  mat-icon-button
                  type="button"
                  matTooltip="Delete {{ thesaurus.id }}"
                  color="warn"
                  (click)="deleteThesaurus(thesaurus)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
                <button
                  type="button"
                  mat-icon-button
                  matTooltip="Download this thesaurus"
                  [disabled]="downloading"
                  (click)="downloadThesaurus(thesaurus.id)"
                >
                  <mat-icon>download</mat-icon>
                </button>
              </td>
              <td>{{ thesaurus.id }}</td>
              <td>
                {{ thesaurus.entries?.length || 0 }}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- pagination -->
        <mat-paginator
          [length]="pagination.total"
          [pageSize]="pagination.perPage"
          [pageSizeOptions]="[20, 50, 75, 100]"
          [pageIndex]="pagination.currentPage - 1"
          [showFirstLastButtons]="true"
          (page)="pageChange($event)"
        ></mat-paginator>

        <!-- importer -->
        <div *ngIf="importEnabled">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>Import thesauri</mat-panel-title>
            </mat-expansion-panel-header>
            <cadmus-thesaurus-import
              (uploadEnd)="onUploadEnd()"
            ></cadmus-thesaurus-import>
          </mat-expansion-panel>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions
    *ngIf="
      user?.roles?.includes('admin') ||
      user?.roles?.includes('editor') ||
      user?.roles?.includes('operator')
    "
  >
    <button
      *ngIf="userLevel > 1"
      type="button"
      color="primary"
      mat-flat-button
      matTooltip="Add a new thesaurus"
      (click)="addThesaurus()"
    >
      <mat-icon>add_circle</mat-icon>
      thesaurus
    </button>
  </mat-card-actions>
</mat-card>
