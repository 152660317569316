<form [formGroup]="form" (ngSubmit)="requestLogin()">
  <div>
    <mat-form-field>
      <mat-label i18n>name</mat-label>
      <input type="text" matInput [formControl]="name" />
      <mat-icon matSuffix>account_circle</mat-icon>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <mat-label i18n>password</mat-label>
      <input
        [type]="hide ? 'password' : 'text'"
        matInput
        [formControl]="password"
      />
      <button
        type="button"
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        i18n-aria-label
        aria-label="Hide password"
        [attr.aria-pressed]="hide"
      >
        <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div *ngIf="errorMessage" class="loginError">
    {{ errorMessage }}
  </div>

  <div>
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="validating"
    ></mat-progress-bar>
  </div>
  <div class="loginButtons">
    <button type="submit" mat-button color="primary" [disabled]="form.invalid">
      <mat-icon>check_circle</mat-icon>
      <ng-container i18n>Login</ng-container>
    </button>
  </div>
  <div *ngIf="forgot" class="tip">
    <a mat-button (click)="requestReset()" i18n>Forgot password?</a>
  </div>
</form>
