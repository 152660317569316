// https://angular.io/docs/ts/latest/guide/router.html
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthJwtService } from './auth-jwt.service';

/**
 * Guard for authenticated users. This assumes that the login route
 * to redirect to is /login.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthJwtGuardService  {
  constructor(
    private _router: Router,
    private _authService: AuthJwtService
  ) {}

  private redirectToLogin(url: string): void {
    this._router.navigate(['/login'], {
      queryParams: {
        returnUrl: url,
      },
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // if not authenticated, redirect to login
    if (!this._authService.isAuthenticated(false)) {
      this.redirectToLogin(state.url);
      return false;
    }
    // if authenticated but not verified, redirect to login
    if (!this._authService.currentUserValue?.emailConfirmed) {
      this.redirectToLogin(state.url);
      console.warn('User not verified');
      return false;
    }
    return true;
  }
}
