import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import '@angular/localize/init';

import { PaginationData } from '@ngneat/elf-pagination';

import { GravatarService, User } from '@myrmidon/auth-jwt-login';

import { DialogService } from '../../services/dialog.service';
import { UserListRepository } from '../state/user-list.repository';

@Component({
  selector: 'auth-jwt-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css'],
})
export class UserListComponent {
  public pagination$: Observable<PaginationData & { data: User[] }>;
  public active$: Observable<User | undefined>;
  public loading$: Observable<boolean>;

  constructor(
    private _repository: UserListRepository,
    private _dialogService: DialogService,
    private _gravatarService: GravatarService
  ) {
    this.pagination$ = _repository.pagination$;
    this.active$ = _repository.activeUser$;
    this.loading$ = _repository.loading$;
  }

  public pageChange(event: PageEvent): void {
    this._repository.loadPage(event.pageIndex + 1, event.pageSize);
  }

  public deleteUser(user: User): void {
    this._dialogService
      .confirm($localize`Confirm`, $localize`Delete user ${user.userName}?`)
      .pipe(take(1))
      .subscribe((yes) => {
        if (!yes) {
          return;
        }
        this._repository.deleteUser(user.userName).finally(() => {
          this._repository.clearCache();
          this._repository.loadPage(1);
        });
      });
  }

  public setActiveUser(user: User | null): void {
    this._repository.setActive(user?.userName || null);
  }

  public resetActiveUser(): void {
    this._repository.setActive(null);
  }

  public saveActiveUser(user: User): void {
    this._repository.updateActive(user);
    this._repository.setActive(null);
  }

  public onUserEditorClose(): void {
    this._repository.setActive(null);
  }

  public getGravatarUrl(email: string, size = 80): string | null {
    return this._gravatarService.buildGravatarUrl(email, size);
  }

  public clearCache(): void {
    this._repository.clearCache();
    this._repository.loadPage(1);
  }
}
