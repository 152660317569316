<form [formGroup]="form" class="form-row" (submit)="save()">
  <!-- relation (bound) -->
  <mat-form-field *ngIf="relationEntries?.length">
    <mat-label>relation</mat-label>
    <mat-select [formControl]="relation">
      <mat-option *ngFor="let e of relationEntries" [value]="e.id">{{
        e.value
      }}</mat-option>
    </mat-select>
    <mat-error
      *ngIf="
        $any(relation).errors?.required && (relation.dirty || relation.touched)
      "
      >relation required</mat-error
    >
  </mat-form-field>
  <!-- relation (free) -->
  <mat-form-field *ngIf="!relationEntries?.length">
    <mat-label>relation</mat-label>
    <input matInput [formControl]="relation" />
    <mat-error
      *ngIf="
        $any(relation).errors?.required && (relation.dirty || relation.touched)
      "
      >relation required</mat-error
    >
    <mat-error
      *ngIf="
        $any(relation).errors?.maxLength && (relation.dirty || relation.touched)
      "
      >relation too long</mat-error
    >
  </mat-form-field>

  <!-- id -->
  <cadmus-refs-asserted-composite-id
    [idScopeEntries]="idScopeEntries"
    [idTagEntries]="idTagEntries"
    [assTagEntries]="assTagEntries"
    [refTagEntries]="refTagEntries"
    [refTypeEntries]="refTypeEntries"
    [pinByTypeMode]="pinByTypeMode"
    [canSwitchMode]="canSwitchMode"
    [canEditTarget]="canEditTarget"
    [id]="id.value"
    [internalDefault]="true"
    (idChange)="onIdChange($event)"
  >
  </cadmus-refs-asserted-composite-id>

  <button mat-icon-button type="button" color="warn" (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
  <button
    mat-icon-button
    type="submit"
    color="primary"
    matTooltip="Save entity"
  >
    <mat-icon>check_circle</mat-icon>
  </button>
</form>
