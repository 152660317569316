<form [formGroup]="form" (submit)="apply()" ng-disabled="disabled">
  <div class="form-row">
    <mat-form-field>
      <mat-label i18n>name or ID</mat-label>
      <input matInput [formControl]="name" />
      <button
        mat-icon-button
        matSuffix
        type="button"
        (click)="reset()"
        color="warn"
        i18n-matTooltip
        matTooltip="Reset filters"
        [disabled]="disabled"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </mat-form-field>

    <button
      style="margin-top: -20px"
      type="submit"
      mat-icon-button
      color="primary"
      [disabled]="disabled"
      i18n-matTooltip
      matTooltip="Apply filters"
    >
      <mat-icon>check_circle</mat-icon>
    </button>
  </div>
</form>
