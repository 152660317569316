<form [formGroup]="form" (submit)="save()">
  <mat-card appearance="outlined">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>picture_in_picture</mat-icon>
      </div>
      <mat-card-title>Note Part</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <!-- free tag -->
      <div *ngIf="!tagEntries">
        <mat-form-field>
          <mat-label>tag</mat-label>
          <input
            type="text"
            matInput
            formControlName="tag"
            spellcheck="false"
          />
          <mat-error
            *ngIf="tag.hasError('maxLength') && (tag.dirty || tag.touched)"
            >tag too long</mat-error
          >
        </mat-form-field>
      </div>
      <!-- bound tag -->
      <div *ngIf="tagEntries">
        <mat-form-field>
          <mat-label>tag</mat-label>
          <mat-select formControlName="tag">
            <mat-option *ngFor="let e of tagEntries" [value]="e.id">{{
              e.value
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- text -->
      <div>
        <ngx-monaco-editor
          [options]="editorOptions"
          formControlName="text"
        ></ngx-monaco-editor>
        <mat-error
          *ngIf="text.hasError('required') && (text.touched || text.dirty)"
          >please enter some text</mat-error
        >
      </div>

      <hr />
      <markdown [data]="text.value || undefined"></markdown>
    </mat-card-content>

    <mat-card-actions>
      <cadmus-close-save-buttons
        [form]="form"
        [noSave]="userLevel < 2"
        (closeRequest)="close()"
      ></cadmus-close-save-buttons>
    </mat-card-actions>
  </mat-card>
</form>
