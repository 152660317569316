<form [formGroup]="form" (submit)="save()">
  <mat-card appearance="outlined">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>picture_in_picture</mat-icon>
      </div>
      <mat-card-title [matBadge]="keywords.value.length" matBadgeOverlap="false"
        >Keywords Part</mat-card-title
      >
    </mat-card-header>
    <mat-card-content>
      <!-- language -->
      <form [formGroup]="newForm" (ngSubmit)="addKeyword()" class="form-row">
        <mat-form-field>
          <mat-label>language</mat-label>
          <mat-select [formControl]="newLanguage">
            <mat-option *ngFor="let t of langEntries" [value]="t.id">
              {{ t.value }}
            </mat-option>
          </mat-select>
          <mat-error>select a language</mat-error>
        </mat-form-field>
        <!-- value -->
        <mat-form-field>
          <mat-label>value</mat-label>
          <input
            matInput
            autofocus
            type="text"
            id="value"
            [formControl]="newValue"
          />
          <mat-error
            *ngIf="
              newValue.hasError('required') &&
              (newValue.touched || newValue.dirty)
            "
            >enter a value</mat-error
          >
          <mat-error
            *ngIf="
              newValue.hasError('maxLength') &&
              (newValue.touched || newValue.dirty)
            "
            >value too long</mat-error
          >
        </mat-form-field>
        <button
          mat-flat-button
          type="submit"
          color="primary"
          [disabled]="newForm.invalid"
        >
          <mat-icon>add_circle</mat-icon> keyword
        </button>
      </form>
      <hr />
      <table *ngIf="keywords.valid">
        <thead>
          <tr>
            <th></th>
            <th>lang.</th>
            <th>keyword</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let k of keywords.value">
            <td>
              <button
                mat-icon-button
                type="button"
                matTooltip="Delete this keyword"
                (click)="deleteKeyword(k)"
              >
                <mat-icon color="warn">remove_circle</mat-icon>
              </button>
            </td>
            <td>{{ k.language }}</td>
            <td>{{ k.value }}</td>
          </tr>
        </tbody>
      </table>
    </mat-card-content>
    <mat-card-actions>
      <cadmus-close-save-buttons
        [form]="form"
        [noSave]="userLevel < 2"
        (closeRequest)="close()"
      ></cadmus-close-save-buttons>
    </mat-card-actions>
  </mat-card>
</form>
