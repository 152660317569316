<mat-card appearance="outlined">
  <mat-card-header>
    <h2>Semantic Graph</h2>
  </mat-card-header>
  <mat-card-content>
    <mat-tab-group>
      <mat-tab label="&#9679; nodes">
        <cadmus-graph-node-list
          [tagEntries]="nodeTagEntries"
        ></cadmus-graph-node-list>
      </mat-tab>
      <mat-tab label="&#9650; triples">
        <cadmus-graph-triple-list
          [tagEntries]="tripleTagEntries"
        ></cadmus-graph-triple-list>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
