<form [formGroup]="form" (submit)="save()">
  <mat-card appearance="outlined">
    <mat-card-content>
      <!-- text -->
      <mat-form-field class="full-width">
        <mat-label>operation</mat-label>
        <input matInput type="text" [formControl]="text" />
        <mat-hint>"a"@AxR="b" [tag] &#123;note&#125;</mat-hint>
        <button
          *ngIf="text.value"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="resetText()"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-error
          *ngIf="text.hasError('required') && (text.touched || text.dirty)"
          >please enter an operation</mat-error
        >
      </mat-form-field>

      <!-- visual -->
      <mat-expansion-panel [(expanded)]="visualExpanded">
        <mat-expansion-panel-header>
          <mat-panel-title> Visual </mat-panel-title>
        </mat-expansion-panel-header>

        <form [formGroup]="visual">
          <!-- operator -->
          <div>
            <mat-form-field>
              <mat-select [formControl]="operator">
                <mat-option [value]="0">delete</mat-option>
                <mat-option [value]="1">replace</mat-option>
                <mat-option [value]="2">insert</mat-option>
                <mat-option [value]="3">move</mat-option>
                <mat-option [value]="4">swap</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- range A -->
          <div class="form-row">
            <mat-form-field>
              <mat-label>A-range</mat-label>
              <input matInput type="text" [formControl]="rangeA" />
              <mat-error
                *ngIf="
                  rangeA.hasError('required') &&
                  (rangeA.touched || rangeA.dirty)
                "
                >please enter a range</mat-error
              >
              <mat-error
                *ngIf="
                  rangeA.hasError('pattern') && (rangeA.touched || rangeA.dirty)
                "
                >invalid range (NxM)</mat-error
              >
            </mat-form-field>

            <!-- value A -->
            <mat-form-field>
              <mat-label>A-value</mat-label>
              <input matInput type="text" [formControl]="valueA" />
              <mat-error
                *ngIf="
                  valueA.hasError('maxLength') &&
                  (valueA.touched || valueA.dirty)
                "
                >too long</mat-error
              >
            </mat-form-field>
          </div>

          <!-- range B -->
          <div class="form-row">
            <mat-form-field>
              <mat-label>B-range</mat-label>
              <input matInput type="text" [formControl]="rangeB" />
              <mat-error
                *ngIf="
                  rangeB.hasError('pattern') && (rangeB.touched || rangeB.dirty)
                "
                >invalid range (NxM)</mat-error
              >
            </mat-form-field>

            <!-- value B -->
            <mat-form-field>
              <mat-label>B-value</mat-label>
              <input matInput type="text" [formControl]="valueB" />
              <mat-error
                *ngIf="
                  valueB.hasError('maxLength') &&
                  (valueB.touched || valueB.dirty)
                "
                >too long</mat-error
              >
            </mat-form-field>
          </div>

          <div class="form-row">
            <!-- tag -->
            <mat-form-field>
              <mat-label>tag</mat-label>
              <input matInput type="text" [formControl]="tag" />
              <mat-error
                *ngIf="tag.hasError('maxLength') && (tag.touched || tag.dirty)"
                >too long</mat-error
              >
              <mat-error
                *ngIf="tag.hasError('pattern') && (tag.touched || tag.dirty)"
                >invalid tag</mat-error
              >
            </mat-form-field>

            <!-- note -->
            <mat-form-field>
              <mat-label>note</mat-label>
              <input matInput type="text" [formControl]="note" />
              <mat-error
                *ngIf="
                  note.hasError('maxLength') && (note.touched || note.dirty)
                "
                >too long</mat-error
              >
              <mat-error
                *ngIf="note.hasError('pattern') && (note.touched || note.dirty)"
                >invalid note</mat-error
              >
            </mat-form-field>
          </div>
        </form>
      </mat-expansion-panel>
    </mat-card-content>
    <mat-card-actions>
      <button
        mat-icon-button
        type="button"
        color="warn"
        matTooltip="Close operation"
        (click)="cancel()"
      >
        <mat-icon>cancel</mat-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        type="submit"
        [disabled]="form.invalid || form.pristine"
        matTooltip="Save operation"
      >
        <mat-icon>check_circle</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</form>
