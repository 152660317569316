<form role="form" [formGroup]="form" (submit)="apply()">
  <div class="form-row">
    <!-- id -->
    <div>
      <mat-form-field>
        <mat-label>ID</mat-label>
        <input matInput formControlName="id" maxlength="100" />
      </mat-form-field>
    </div>

    <!-- language -->
    <div>
      <mat-form-field>
        <mat-label>language</mat-label>
        <input matInput formControlName="language" maxlength="2" />
      </mat-form-field>
    </div>

    <!-- alias -->
    <div>
      <mat-form-field>
        <mat-label>alias</mat-label>
        <mat-select formControlName="alias">
          <mat-option [value]="null">(any)</mat-option>
          <mat-option [value]="true">alias</mat-option>
          <mat-option [value]="false">not an alias</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div>
    <button
      type="button"
      mat-icon-button
      (click)="reset()"
      color="warn"
      matTooltip="Reset all the filters"
    >
      <mat-icon>clear</mat-icon>
    </button>
    <button
      type="submit"
      mat-icon-button
      color="primary"
      [disabled]="form.invalid"
      matTooltip="Apply filters"
    >
      <mat-icon>check_circle</mat-icon>
    </button>
  </div>
</form>
