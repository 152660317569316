<div id="container">
  <!-- filters -->
  <div id="filters">
    <mat-card appearance="outlined">
      <mat-card-content>
        <cadmus-graph-triple-filter
          [disabled]="(status$ | async)?.value === 'pending'"
        ></cadmus-graph-triple-filter>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- list -->
  <div id="list" *ngIf="pagination$ | async as pagination">
    <div *ngIf="(status$ | async)?.value === 'pending'">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <!-- list -->
    <div *ngIf="pagination$ | async as pagination">
      <table>
        <thead>
          <th></th>
          <th>ID</th>
          <th>S</th>
          <th>P</th>
          <th>O</th>
          <th class="noif-lt-md">sid</th>
          <th class="noif-lt-md">tag</th>
        </thead>
        <tbody>
          <tr *ngFor="let d of pagination.data">
            <td class="fit-width">
              <button
                mat-icon-button
                type="button"
                color="primary"
                matTooltip="Edit triple"
                (click)="editTriple(d)"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                mat-icon-button
                type="button"
                color="warn"
                matTooltip="Delete triple"
                (click)="deleteTriple(d)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </td>
            <td>{{ d.id }}</td>
            <td>{{ d.subjectUri }}</td>
            <td>{{ d.predicateUri }}</td>
            <td>{{ d.objectUri ?? d.objectLiteral | ellipsis }}</td>
            <td class="noif-lt-md">{{ d.sid }}</td>
            <td class="noif-lt-md">{{ d.tag }}</td>
          </tr>
        </tbody>
      </table>
      <div>
        <button
          color="primary"
          type="button"
          mat-flat-button
          (click)="addTriple()"
        >
          <mat-icon>add_circle</mat-icon>
          add triple
        </button>
      </div>

      <!-- pagination -->
      <div id="paginator" class="form-row">
        <button
          color="primary"
          type="button"
          mat-icon-button
          matTooltip="Clear cache"
          (click)="clearCache()"
          color="warn"
        >
          <mat-icon>autorenew</mat-icon>
        </button>

        <mat-paginator
          [length]="pagination.total"
          [pageSize]="pagination.perPage"
          [pageSizeOptions]="[20, 50, 75, 100]"
          [pageIndex]="pagination.currentPage - 1"
          [showFirstLastButtons]="true"
          (page)="pageChange($event)"
        ></mat-paginator>
      </div>
    </div>

    <!-- editor -->
    <mat-expansion-panel
      id="editor"
      [expanded]="editedTriple"
      [disabled]="!editedTriple"
    >
      <mat-expansion-panel-header>Triple</mat-expansion-panel-header>
      <cadmus-graph-triple-editor
        [triple]="editedTriple"
        [tagEntries]="tagEntries"
        (tripleChange)="onTripleChange($event)"
        (editorClose)="onEditorClose()"
      ></cadmus-graph-triple-editor>
    </mat-expansion-panel>
  </div>
</div>
