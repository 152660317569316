<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>Item Search</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div id="container">
      <!-- query -->
      <div id="query">
        <cadmus-item-query
          [lastQueries]="(lastQueries$ | async) || []"
          (querySubmit)="submitQuery($event)"
        ></cadmus-item-query>
      </div>

      <!-- list -->
      <div id="list" *ngIf="pagination$ | async as pagination">
        <div *ngIf="loading$ | async">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div *ngIf="error$ | async as error">{{ error }}</div>
        <table *ngIf="pagination.data.length">
          <thead>
            <th></th>
            <th>facet</th>
            <th>flags</th>
            <th>title</th>
            <th>description</th>
            <th class="noif-lt-md">group</th>
            <th class="noif-lt-md">user</th>
            <th class="noif-lt-md">last saved</th>
            <th class="noif-lt-md">creator</th>
          </thead>
          <tbody>
            <tr *ngFor="let item of pagination.data">
              <td class="fit-width">
                <button
                  mat-icon-button
                  type="button"
                  color="primary"
                  (click)="editItem(item)"
                >
                  <mat-icon>mode_edit</mat-icon>
                </button>
                <button
                  *ngIf="userLevel > 2"
                  mat-icon-button
                  type="button"
                  color="warn"
                  (click)="deleteItem(item)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
              <td class="fit-width">
                <cadmus-facet-badge
                  [data]="{ definitions: app.facets, facetId: item.facetId }"
                ></cadmus-facet-badge>
              </td>
              <td class="fit-width">
                <cadmus-flags-badge
                  [data]="{ definitions: app.flags, flags: item.flags }"
                ></cadmus-flags-badge>
              </td>
              <td>{{ item.title }}</td>
              <td>{{ item.description }}</td>
              <td class="noif-lt-md">{{ item.groupId }}</td>
              <td class="noif-lt-md">{{ item.userId }}</td>
              <td class="noif-lt-md">
                {{ item.timeModified | date : "yyyy-MM-dd HH:mm:ss" }}
              </td>
              <td class="noif-lt-md">{{ item.creatorId }}</td>
            </tr>
          </tbody>
        </table>

        <!-- paginator -->
        <div class="form-row">
          <button
            type="button"
            mat-icon-button
            color="warn"
            matTooltip="Clear items cache"
            (click)="clearCache()"
          >
            <mat-icon>autorenew</mat-icon>
          </button>

          <mat-paginator
            *ngIf="pagination.data.length"
            [length]="pagination.total"
            [pageSize]="pagination.perPage"
            [pageSizeOptions]="[20, 50, 75, 100]"
            [pageIndex]="pagination.currentPage - 1"
            [showFirstLastButtons]="true"
            (page)="pageChange($event)"
          ></mat-paginator>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
