<form [formGroup]="form" (submit)="save()">
  <div class="form-row">
    <!-- email -->
    <mat-form-field>
      <mat-label i18n>email</mat-label>
      <input type="text" matInput [formControl]="email" />
      <mat-error
        i18n
        *ngIf="email.hasError('required') && (email.dirty || email.touched)"
      >
        email address required
      </mat-error>
      <mat-error
        i18n
        *ngIf="email.hasError('pattern') && (email.dirty || email.touched)"
      >
        invalid email address
      </mat-error>
    </mat-form-field>

    <!-- emailConfirmed -->
    <mat-checkbox [formControl]="emailConfirmed" i18n
      >email address confirmed</mat-checkbox
    >
  </div>

  <!-- lockoutEnabled -->
  <div class="form-row">
    <mat-checkbox [formControl]="lockoutEnabled" i18n
      >lockout enabled</mat-checkbox
    >
    <button
      mat-icon-button
      color="primary"
      (click)="endLockout()"
      [disabled]="unlocked"
      i18n-matTooltip
      matTooltip="Unlock this user if locked"
    >
      <mat-icon>lock_open</mat-icon>
    </button>
  </div>

  <div class="form-row">
    <!-- firstName -->
    <mat-form-field>
      <mat-label i18n>first name</mat-label>
      <input type="text" matInput [formControl]="firstName" />
      <mat-error
        i18n
        *ngIf="
          firstName.hasError('required') &&
          (firstName.dirty || firstName.touched)
        "
      >
        first name required
      </mat-error>
      <mat-error
        i18n
        *ngIf="
          firstName.hasError('maxlength') &&
          (firstName.dirty || firstName.touched)
        "
      >
        first name too long
      </mat-error>
    </mat-form-field>

    <!-- lastName -->
    <mat-form-field>
      <mat-label i18n>last name</mat-label>
      <input type="text" matInput [formControl]="lastName" />
      <mat-error
        i18n
        *ngIf="
          lastName.hasError('required') && (lastName.dirty || lastName.touched)
        "
      >
        last name required
      </mat-error>
      <mat-error
        i18n
        *ngIf="
          lastName.hasError('maxlength') && (lastName.dirty || lastName.touched)
        "
      >
        last name too long
      </mat-error>
    </mat-form-field>
  </div>

  <!-- roles -->
  <div>
    <mat-form-field style="width: 16em">
      <mat-label i18n>roles</mat-label>
      <input type="text" matInput [formControl]="roles" />
      <mat-error
        i18n
        *ngIf="roles.hasError('maxlength') && (roles.dirty || roles.touched)"
      >
        too long
      </mat-error>
      <mat-hint i18n>roles (separated by space)</mat-hint>
    </mat-form-field>
  </div>

  <!-- buttons -->
  <br />
  <div>
    <button
      type="button"
      mat-icon-button
      color="warn"
      i18n-matTooltip
      matTooltip="Close"
      (click)="close()"
    >
      <mat-icon>cancel</mat-icon>
    </button>
    <button
      type="submit"
      mat-icon-button
      color="primary"
      [disabled]="form.invalid"
      i18n-matTooltip
      matTooltip="Save user"
    >
      <mat-icon>check_circle</mat-icon>
    </button>
  </div>
</form>
