<form [formGroup]="form" (submit)="apply()" ng-disabled="disabled">
  <div class="form-row">
    <!-- label -->
    <div>
      <mat-form-field>
        <mat-label>label</mat-label>
        <input matInput [formControl]="label" />
      </mat-form-field>
    </div>

    <!-- uid -->
    <div>
      <mat-form-field>
        <mat-label>uid</mat-label>
        <input matInput [formControl]="uid" />
      </mat-form-field>
    </div>

    <!-- isClass -->
    <div>
      <mat-form-field>
        <mat-label>class</mat-label>
        <mat-select [formControl]="isClass">
          <mat-option [value]="0">(any)</mat-option>
          <mat-option [value]="1">not-class</mat-option>
          <mat-option [value]="2">class</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- tag -->
    <div>
      <mat-form-field>
        <mat-label>tag</mat-label>
        <input matInput [formControl]="tag" />
      </mat-form-field>
    </div>

    <!-- sourceType -->
    <div>
      <mat-form-field>
        <mat-label>source type</mat-label>
        <mat-select [formControl]="sourceType">
          <mat-option [value]="null">(any)</mat-option>
          <mat-option [value]="0">user</mat-option>
          <mat-option [value]="1">item</mat-option>
          <mat-option [value]="2">part</mat-option>
          <mat-option [value]="3">thesaurus</mat-option>
          <mat-option [value]="4">implicit</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- sid, sidPrefix -->
    <div>
      <mat-form-field>
        <mat-label>source ID</mat-label>
        <input matInput [formControl]="sid" />
      </mat-form-field>
      &nbsp;
      <mat-checkbox [formControl]="sidPrefix">prefix</mat-checkbox>
    </div>

    <!-- linkedNode, linkedNodeRole -->
    <div>
      <fieldset>
        <legend>linked node</legend>
        <cadmus-ref-lookup
          label="node"
          [item]="linkedNode$ | async"
          [service]="lookupService"
          (itemChange)="onLinkedNodeSet($event)"
        ></cadmus-ref-lookup>
        <div *ngIf="linkedNode$ | async">
          <mat-form-field>
            <mat-label>role</mat-label>
            <mat-select [formControl]="linkedNodeRole">
              <mat-option value="S">subject</mat-option>
              <mat-option value="O">object</mat-option>
            </mat-select>
          </mat-form-field>
          <button
            type="button"
            mat-icon-button
            color="warn"
            matTooltip="Reset linked node"
            (click)="clearLinkedNode()"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </fieldset>
    </div>

    <!-- classes -->
    <div>
      <fieldset>
        <legend>classes</legend>
        <cadmus-ref-lookup
          label="class"
          [service]="lookupService"
          [baseFilter]="{ isClass: true }"
          (itemChange)="onClassAdd($event)"
        ></cadmus-ref-lookup>
        <mat-chip-listbox *ngIf="classNodes$ | async as classNodes">
          <mat-chip-option
            *ngFor="let node of classNodes"
            [removable]="true"
            (removed)="onClassRemove(node.id)"
            matTooltip="{{ node.uri }}"
            >{{ node.label }}
            <button type="button" matChipRemove>
              <mat-icon>clear</mat-icon>
            </button>
          </mat-chip-option>
        </mat-chip-listbox>
      </fieldset>
    </div>
  </div>

  <div id="toolbar" class="btn-group" role="group" aria-label="toolbar">
    <button
      mat-icon-button
      type="button"
      (click)="reset()"
      color="warn"
      matTooltip="Reset filters"
      [disabled]="disabled"
    >
      <mat-icon>clear</mat-icon>
    </button>
    <button
      type="submit"
      mat-icon-button
      color="primary"
      [disabled]="disabled"
      matTooltip="Apply filters"
    >
      <mat-icon>check_circle</mat-icon>
    </button>
  </div>
</form>
