<ng-container *ngIf="form">
  <button type="button" mat-raised-button color="warn" (click)="close()">
    <mat-icon>cancel</mat-icon>
    close
  </button>
  <button
    *ngIf="!noSave"
    type="submit"
    [disabled]="form.invalid"
    mat-raised-button
    color="primary"
    style="margin-left:8px"
  >
    <mat-icon>check_circle</mat-icon>
    save
  </button>
</ng-container>
