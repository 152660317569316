<header>
  <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="start center">
    <span id="logo"
      ><img src="./assets/img/logo-white-40.png" alt="Fusisoft"
    /></span>
    <a mat-button routerLink="/home">Cadmus CHIGC</a>

    <!-- items menu -->
    <button
      type="button"
      mat-button
      [matMenuTriggerFor]="itemMenu"
      *ngIf="logged && itemBrowsers"
    >
      Items
    </button>
    <mat-menu #itemMenu>
      <a mat-menu-item routerLink="/items">Items</a>
      <a
        mat-menu-item
        *ngFor="let entry of itemBrowsers"
        [routerLink]="'item-browser/' + getItemBrowserRoute(entry.id)"
        >{{ entry.value }}</a
      >
    </mat-menu>
    <!-- item menu -->
    <ng-container *ngIf="logged && !itemBrowsers">
      <button type="button" mat-button routerLink="/items">Items</button>
    </ng-container>

    <!-- search menu -->
    <button type="button" mat-button routerLink="/search" *ngIf="logged">
      Search
    </button>

    <!-- resources menu -->
    <button
      type="button"
      mat-button
      [matMenuTriggerFor]="resourceMenu"
      *ngIf="logged"
    >
      Resources
    </button>
    <mat-menu #resourceMenu>
      <a
        mat-menu-item
        routerLink="/thesauri"
        *ngIf="
          user &&
          (user.roles.includes('admin') || user.roles.includes('editor'))
        "
        >Thesauri</a
      >
      <a
        mat-menu-item
        routerLink="/import/thesauri"
        *ngIf="user && user.roles.includes('admin')"
        >Import thesauri</a
      >
      <a mat-menu-item routerLink="/import/group">Import pages</a>
      <a mat-menu-item routerLink="/export">Export TEI</a>
      <a mat-menu-item routerLink="/options">Options</a>
    </mat-menu>

    <!-- graph menu -->
    <!-- <button mat-button routerLink="/graph" *ngIf="logged">Graph</button> -->

    <!-- demo menu -->
    <!-- <button type="button" mat-button [matMenuTriggerFor]="demoMenu">
      Demo
    </button>
    <mat-menu #demoMenu>
      <button type="button" mat-menu-item routerLink="/demo/layers">
        Text Layers
      </button>
    </mat-menu> -->

    <span class="tb-fill-remaining-space"></span>

    <!-- user -->
    <div *ngIf="user" fxLayout="row" fxLayoutAlign="start center">
      <!-- indicators -->
      <span id="gallery" *ngIf="galleryId">{{ galleryId }}</span>
      <img
        alt="avatar"
        [src]="getGravatarUrl(user.email, 32)"
        [alt]="user.userName"
      />
      <mat-icon
        class="small-icon"
        *ngIf="user && user.roles.includes('admin')"
        title="admin"
        >build</mat-icon
      >
      <mat-icon
        class="small-icon"
        *ngIf="user && !user.emailConfirmed"
        title="You must verify your email address! Please check your mailbox {{
          user.email
        }}"
        >feedback</mat-icon
      >

      <!-- user menu -->
      <button mat-button [matMenuTriggerFor]="userMenu">User</button>
      <mat-menu #userMenu>
        <a mat-menu-item routerLink="/reset-password">Reset password</a>
      </mat-menu>

      <!-- admin menu -->
      <button
        *ngIf="user && user.roles.includes('admin')"
        mat-button
        [matMenuTriggerFor]="adminMenu"
      >
        Admin
      </button>
      <mat-menu #adminMenu>
        <a mat-menu-item routerLink="/manage-users">Manage users</a>
        <a mat-menu-item routerLink="/register-user">Register user</a>
      </mat-menu>
    </div>

    <!-- login -->
    <button type="button" *ngIf="!logged" mat-icon-button routerLink="/login">
      <mat-icon>login</mat-icon>
    </button>
    <!-- logout -->
    <button type="button" *ngIf="logged" mat-icon-button (click)="logout()">
      <mat-icon>logout</mat-icon>
    </button>
  </mat-toolbar>
</header>

<main>
  <router-outlet></router-outlet>
</main>

<footer>
  <div layout="row" layout-align="center center">
    <p>
      Cadmus by
      <a rel="noopener" href="http://www.fusisoft.it" target="_blank"
        >Daniele Fusi</a
      >
      - version {{ version }}
    </p>
  </div>
</footer>
