<form [formGroup]="form" (submit)="save()">
  <mat-card appearance="outlined">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>picture_in_picture</mat-icon>
      </div>
      <mat-card-title>Tiled Text Part</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <mat-tab-group [(selectedIndex)]="currentTabIndex">
        <mat-tab label="Text">
          <!-- citation -->
          <div>
            <mat-form-field style="min-width: 400px">
              <mat-label>citation</mat-label>
              <input matInput formControlName="citation" />
              <mat-error
                *ngIf="
                  citation.hasError('maxLength') &&
                  (citation.dirty || citation.touched)
                "
              ></mat-error>
            </mat-form-field>
          </div>

          <!-- rows -->
          <div *ngIf="rows" cdkDropListGroup>
            <!-- row -->
            <div
              cdkDropList
              cdkDropListOrientation="horizontal"
              *ngFor="
                let row of rows.value;
                let i = index;
                let first = first;
                let last = last
              "
              (cdkDropListDropped)="drop($any($event), row)"
              style="display: flex; flex-flow: row wrap"
            >
              <!-- nr -->
              <span class="row-nr">{{ i + 1 }}</span>

              <!-- tile -->
              <cadmus-text-tile
                *ngFor="let tile of row.tiles || []"
                [tile]="tile"
                [selected]="tile === selectedTile"
                cdkDrag
                style="flex: 0 0 auto"
                (click)="selectedTile = tile"
                (editData)="editTileData(tile)"
                (tileChange)="onTileChange($event)"
              >
                <div class="tile-placeholder" *cdkDragPlaceholder></div>
              </cadmus-text-tile>
              <!-- row tail buttons -->
              <div class="unmuted-hovering">
                <button
                  type="button"
                  mat-icon-button
                  (click)="addTile(row)"
                  matTooltip="Append new tile"
                >
                  <mat-icon>add_circle</mat-icon>
                </button>
                <button
                  type="button"
                  mat-icon-button
                  (click)="moveRowUp(i)"
                  [disabled]="first"
                  matTooltip="Move this row up"
                >
                  <mat-icon>arrow_upward</mat-icon>
                </button>
                <button
                  type="button"
                  mat-icon-button
                  (click)="moveRowDown(i)"
                  [disabled]="last"
                  matTooltip="Move this row down"
                >
                  <mat-icon>arrow_downward</mat-icon>
                </button>
                <button
                  type="button"
                  mat-icon-button
                  matTooltip="Delete whole row"
                  color="warn"
                  (click)="deleteRow(i)"
                >
                  <mat-icon>remove_circle</mat-icon>
                </button>
                <button
                  type="button"
                  mat-icon-button
                  matTooltip="Edit row's data"
                  (click)="editRowData(row)"
                >
                  <mat-icon>list</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <!-- bottom buttons -->
          <button
            type="button"
            mat-icon-button
            matTooltip="Append new row"
            (click)="addRow()"
          >
            <mat-icon>add_circle</mat-icon>
          </button>
          <span>{{ getTileCoords() }}</span>
          <button
            type="button"
            mat-icon-button
            matTooltip="Edit selected tile's data"
            [disabled]="!selectedTile"
            (click)="editTileData(selectedTile!)"
          >
            <mat-icon>list</mat-icon>
          </button>
          <button
            type="button"
            mat-icon-button
            matTooltip="Delete the selected tile"
            color="warn"
            (click)="deleteSelectedTile()"
            [disabled]="!selectedTile"
          >
            <mat-icon>remove_circle</mat-icon>
          </button>

          <mat-card-actions>
            <cadmus-close-save-buttons
              [form]="form"
              [noSave]="userLevel < 2"
              (closeRequest)="close()"
            ></cadmus-close-save-buttons>
          </mat-card-actions>
        </mat-tab>

        <mat-tab label="Data" *ngIf="editedData">
          <cadmus-tiled-data
            [data]="editedData"
            [title]="editedDataTitle"
            [hiddenKeys]="['text']"
            (cancel)="closeDataEditor()"
            (dataChange)="saveEditedData($event)"
          ></cadmus-tiled-data>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</form>
