import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { UserFilter } from '../../services/auth-jwt-account.service';
import { UserListRepository } from '../state/user-list.repository';

@Component({
  selector: 'auth-jwt-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.css'],
})
export class UserFilterComponent implements OnInit {
  @Input()
  public disabled: boolean | undefined;

  public filter$: Observable<UserFilter>;

  public name: FormControl<string | null>;
  public form: FormGroup;

  constructor(
    formBuilder: FormBuilder,
    private _repository: UserListRepository
  ) {
    this.filter$ = _repository.filter$;

    // form
    this.name = formBuilder.control<string | null>(null);
    this.form = formBuilder.group({
      name: this.name,
    });
  }

  ngOnInit(): void {
    this.filter$.subscribe((f) => {
      this.updateForm(f);
    });
  }

  private updateForm(filter: UserFilter): void {
    this.name.setValue(filter.name || null);
    this.form.markAsPristine();
  }

  public reset(): void {
    this.form.reset();
    this.apply();
  }

  private getFilter(): UserFilter {
    return {
      name: this.name.value?.trim(),
    };
  }

  public apply(): void {
    if (this.form.invalid) {
      return;
    }
    const filter = this.getFilter();

    // update filter in state
    this._repository.setFilter(filter);
  }
}
