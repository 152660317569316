import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  map,
  Observable,
  take,
} from 'rxjs';

import { createStore, select, withProps } from '@ngneat/elf';
import {
  deleteAllEntities,
  selectActiveEntity,
  setActiveId,
  updateEntities,
  upsertEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import {
  deleteAllPages,
  hasPage,
  PaginationData,
  selectCurrentPageEntities,
  selectPaginationData,
  setCurrentPage,
  setPage,
  updatePaginationData,
  withPagination,
} from '@ngneat/elf-pagination';
import {
  selectRequestStatus,
  StatusState,
  updateRequestStatus,
  withRequestsCache,
  withRequestsStatus,
} from '@ngneat/elf-requests';

import { User } from '@myrmidon/auth-jwt-login';
import { DataPage } from '@myrmidon/ng-tools';

import {
  AuthJwtAccountService,
  UserFilter,
} from '../../services/auth-jwt-account.service';

const PAGE_SIZE = 20;

export interface UserListProps {
  filter: UserFilter;
}

@Injectable({ providedIn: 'root' })
export class UserListRepository {
  private _store;
  private _lastPageSize: number;
  private _loading$: BehaviorSubject<boolean>;
  private _saving$: BehaviorSubject<boolean>;

  public activeUser$: Observable<User | undefined>;
  public filter$: Observable<UserFilter>;
  public pagination$: Observable<PaginationData & { data: User[] }>;
  public status$: Observable<StatusState>;
  public loading$: Observable<boolean>;
  public saving$: Observable<boolean>;

  constructor(private _accService: AuthJwtAccountService) {
    // create store
    this._store = this.createStore();
    this._lastPageSize = PAGE_SIZE;
    this._loading$ = new BehaviorSubject<boolean>(false);
    this._saving$ = new BehaviorSubject<boolean>(false);
    this.loading$ = this._loading$.asObservable();
    this.saving$ = this._saving$.asObservable();
    // combine pagination parameters with page data for our consumers
    this.pagination$ = combineLatest([
      this._store.pipe(selectPaginationData()),
      this._store.pipe(selectCurrentPageEntities()),
    ]).pipe(
      map(([pagination, data]) => ({ ...pagination, data })),
      debounceTime(0)
    );
    // the active user, if required
    this.activeUser$ = this._store.pipe(selectActiveEntity());
    // the filter, if required
    this.filter$ = this._store.pipe(select((state) => state.filter));
    this.filter$.subscribe((filter) => {
      // when filter changed, reset any existing page and move to page 1
      const paginationData = this._store.getValue().pagination;
      this._store.update(deleteAllPages());
      // load page 1
      this.loadPage(1, paginationData.perPage);
    });

    // the request status
    this.status$ = this._store.pipe(selectRequestStatus('user-list'));

    // load page 1 and subscribe to pagination
    this.loadPage(1, PAGE_SIZE);
    this.pagination$.subscribe(console.log);
  }

  private createStore(): typeof store {
    const store = createStore(
      { name: 'user-list' },
      withProps<UserListProps>({
        filter: {},
      }),
      withEntities<User, 'userName'>({ idKey: 'userName' }),
      withActiveId(),
      withRequestsCache<'user-list'>(),
      withRequestsStatus(),
      withPagination()
    );

    return store;
  }

  private adaptPage(page: DataPage<User>): PaginationData & { data: User[] } {
    // adapt the server page DataPage<T> to Elf pagination
    return {
      currentPage: page.pageNumber,
      perPage: page.pageSize,
      lastPage: page.pageCount,
      total: page.total,
      data: page.items,
    };
  }

  private addPage(response: PaginationData & { data: User[] }): void {
    const { data, ...paginationData } = response;
    this._store.update(
      upsertEntities(data),
      updatePaginationData(paginationData),
      setPage(
        paginationData.currentPage,
        data.map((c) => c.userName)
      )
    );
  }

  public loadPage(pageNumber: number, pageSize?: number): void {
    if (!pageSize) {
      pageSize = PAGE_SIZE;
    }
    // if the page exists and page size is the same, just move to it
    if (
      this._store.query(hasPage(pageNumber)) &&
      pageSize === this._lastPageSize
    ) {
      console.log('Page exists: ' + pageNumber);
      this._store.update(setCurrentPage(pageNumber));
      return;
    }

    // reset cached pages if page size changed
    if (this._lastPageSize !== pageSize) {
      this._store.update(deleteAllPages());
      this._lastPageSize = pageSize;
    }

    // load page from server
    this._store.update(updateRequestStatus('user-list', 'pending'));
    this._loading$.next(true);
    this._accService
      .getUsers(this._store.getValue().filter, pageNumber, pageSize)
      .pipe(take(1))
      .subscribe((page) => {
        this._loading$.next(false);
        this.addPage({ ...this.adaptPage(page), data: page.items });
        this._store.update(updateRequestStatus('user-list', 'success'));
      });
  }

  public setFilter(filter: UserFilter): void {
    this._store.update((state) => ({ ...state, filter: filter }));
  }

  clearCache() {
    this._store.update(deleteAllEntities(), deleteAllPages());
  }

  public setActive(name: string | null): void {
    this._store.update(setActiveId(name));
  }

  public updateActive(user: User): Promise<boolean> {
    const promise: Promise<boolean> = new Promise((resolve, reject) => {
      this._saving$.next(true);

      this._accService.updateUser(user).subscribe({
        next: (_) => {
          this._saving$.next(false);
          this._store.update(upsertEntities(user));
          resolve(true);
        },
        error: (error) => {
          this._saving$.next(false);
          console.error(
            `Error updating user ${user.userName}: ` +
              JSON.stringify(error || {})
          );
          resolve(false);
        },
      });
    });
    return promise;
  }

  public deleteUser(name: string): Promise<boolean> {
    const promise: Promise<boolean> = new Promise((resolve, reject) => {
      this._saving$.next(true);

      this._accService.deleteUser(name).subscribe({
        next: (_) => {
          this._saving$.next(false);
          this.clearCache();
          this.loadPage(1);
          resolve(true);
        },
        error: (error) => {
          this._saving$.next(false);
          console.error(
            `Error deleting user ${name}: ` + JSON.stringify(error || {})
          );
          reject(error);
        },
      });
    });
    return promise;
  }
}
