<form [formGroup]="form">
  <mat-button-toggle-group formControlName="tool">
    <mat-button-toggle
      *ngFor="let t of tools"
      [value]="t.id"
      [matTooltip]="t.tip"
    >
      <mat-icon> {{ t.icon }}</mat-icon></mat-button-toggle
    >
  </mat-button-toggle-group>
</form>
