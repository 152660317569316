<form [formGroup]="form" class="form-row" *ngIf="piece" (submit)="save()">
  <!-- type (bound) -->
  <mat-form-field *ngIf="types?.length">
    <mat-label>type</mat-label>
    <mat-select [formControl]="type">
      <mat-option *ngFor="let e of types" [value]="e">{{ e.value }}</mat-option>
    </mat-select>
    <mat-error
      *ngIf="$any(type).errors?.required && (type.dirty || type.touched)"
      >type required</mat-error
    >
  </mat-form-field>

  <!-- type (free) -->
  <mat-form-field *ngIf="!types?.length">
    <mat-label>type</mat-label>
    <input matInput [formControl]="type" />
    <mat-error
      *ngIf="$any(type).errors?.required && (type.dirty || type.touched)"
      >type required</mat-error
    >
    <mat-error
      *ngIf="$any(type).errors?.maxLength && (type.dirty || type.touched)"
      >type too long</mat-error
    >
  </mat-form-field>

  <!-- value (bound) -->
  <mat-form-field *ngIf="typeValues?.length">
    <mat-label>value</mat-label>
    <mat-select [formControl]="value">
      <mat-option *ngFor="let e of typeValues" [value]="e">{{
        e.value
      }}</mat-option>
    </mat-select>
    <mat-error
      *ngIf="$any(value).errors?.required && (value.dirty || value.touched)"
      >value required</mat-error
    >
  </mat-form-field>

  <!-- value (free) -->
  <mat-form-field *ngIf="!typeValues?.length">
    <mat-label>value</mat-label>
    <input #valuectl matInput [formControl]="value" maxlength="100" />
    <mat-error
      *ngIf="$any(value).errors?.required && (value.dirty || value.touched)"
      >value required</mat-error
    >
    <mat-error
      *ngIf="$any(value).errors?.maxLength && (value.dirty || value.touched)"
      >value too long</mat-error
    >
  </mat-form-field>

  <div>
    <button type="button" color="warn" mat-icon-button (click)="cancel()">
      <mat-icon>cancel</mat-icon>
    </button>

    <button
      type="submit"
      color="primary"
      mat-icon-button
      [disabled]="form.invalid || form.pristine"
    >
      <mat-icon>check_circle</mat-icon>
    </button>
  </div>
</form>
