<div id="container">
  <div>
    <!-- filters -->
    <div id="filters">
      <auth-jwt-user-filter></auth-jwt-user-filter>
    </div>

    <!-- list -->
    <div id="list" *ngIf="pagination$ | async as pagination">
      <div *ngIf="loading$ | async" gdArea="progress">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
      <table>
        <thead>
          <td></td>
          <td></td>
          <th i18n>name</th>
          <th i18n class="noif-lt-md">first</th>
          <th i18n class="noif-lt-md">last</th>
          <th i18n class="noif-lt-md">email</th>
          <th i18n>roles</th>
          <th i18n class="noif-lt-md">lock end</th>
        </thead>
        <tbody>
          <tr *ngFor="let user of pagination.data">
            <td>
              <button
                mat-icon-button
                type="button"
                i18n-matTooltip
                matTooltip="Edit this user"
                color="primary"
                (click)="setActiveUser(user)"
              >
                <mat-icon>mode_edit</mat-icon>
              </button>
              <button
                mat-icon-button
                type="button"
                i18n-matTooltip
                matTooltip="Delete this user"
                color="warn"
                (click)="deleteUser(user)"
              >
                <mat-icon>remove_circle</mat-icon>
              </button>
            </td>
            <td>
              <img
                alt="avatar"
                [src]="getGravatarUrl(user.email, 32)"
                [alt]="user.userName"
              />
            </td>
            <td>{{ user.userName }}</td>
            <td class="noif-lt-md">{{ user.firstName }}</td>
            <td class="noif-lt-md">{{ user.lastName }}</td>
            <td class="noif-lt-md">
              <a [href]="'mailto:' + user.email">{{ user.email }}</a>
            </td>
            <td>{{ user.roles.join(" ") }}</td>
            <td class="noif-lt-md">{{ user.lockoutEnd }}</td>
          </tr>
        </tbody>
      </table>

      <!-- paginator -->
      <div id="paginator" class="form-row">
        <button
          type="button"
          mat-icon-button
          color="warn"
          i18n-matmatTooltip
          matTooltip="Clear items cache"
          (click)="clearCache()"
        >
          <mat-icon>autorenew</mat-icon>
        </button>

        <mat-paginator
          gdArea="pager"
          gdAlignColumns="center"
          gdAlignRows="start"
          [length]="pagination.total"
          [pageSize]="pagination.perPage"
          [pageSizeOptions]="[20, 50, 75, 100]"
          [pageIndex]="pagination.currentPage - 1"
          [showFirstLastButtons]="true"
          (page)="pageChange($event)"
        ></mat-paginator>
      </div>
    </div>
  </div>

  <!-- editor -->
  <mat-expansion-panel
    id="editor"
    [expanded]="active$ | async"
    [disabled]="!(active$ | async)"
  >
    <div *ngIf="active$ | async as active">
      <fieldset>
        <legend>{{ active.userName }}</legend>
        <auth-jwt-user-editor
          [user]="active"
          (userChange)="saveActiveUser($event)"
          (editorClose)="resetActiveUser()"
        ></auth-jwt-user-editor>
      </fieldset>
    </div>
  </mat-expansion-panel>
</div>
