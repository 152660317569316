<form [formGroup]="form">
  <mat-autocomplete #lookupAuto="matAutocomplete" [displayWith]="entryToName">
    <mat-option
      *ngFor="let entry of entries$ | async"
      [value]="entry"
      (onSelectionChange)="pickEntry(entry)"
    >
      {{ entry?.value }}
    </mat-option>
  </mat-autocomplete>

  <mat-form-field>
    <input
      matInput
      type="text"
      [placeholder]="label"
      [formControl]="lookup"
      [matAutocomplete]="lookupAuto"
    />
  </mat-form-field>
  <button
    type="button"
    mat-icon-button
    color="warn"
    (click)="clear()"
    [disabled]="!entry"
    matTooltip="Clear"
  >
    <mat-icon>clear</mat-icon>
  </button>
</form>
