import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';

import { AuthJwtLoginModule } from '@myrmidon/auth-jwt-login';
import { NgToolsModule } from '@myrmidon/ng-tools';

import { AuthJwtRegistrationComponent } from './components/auth-jwt-registration/auth-jwt-registration.component';
import { PasswordStrengthBarComponent } from './components/password-strength-bar/password-strength-bar.component';
import { UserFilterComponent } from './components/user-filter/user-filter.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { UserEditorComponent } from './components/user-editor/user-editor.component';

@NgModule({
  declarations: [
    AuthJwtRegistrationComponent,
    PasswordStrengthBarComponent,
    UserFilterComponent,
    UserListComponent,
    ConfirmDialogComponent,
    UserEditorComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    // material
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTooltipModule,
    AuthJwtLoginModule,
    NgToolsModule
  ],
  exports: [
    AuthJwtRegistrationComponent,
    PasswordStrengthBarComponent,
    UserFilterComponent,
    UserListComponent
  ],
})
export class AuthJwtAdminModule {}
