<form [formGroup]="form" (submit)="submit()">
  <div class="form-row">
    <!-- index ID -->
    <div *ngIf="idxEntries?.length">
      <mat-form-field>
        <mat-label>index ID</mat-label>
        <mat-select [formControl]="indexId">
          <mat-option [value]="''">(default)</mat-option>
          <mat-option *ngFor="let e of idxEntries" [value]="e.id">{{
            e.value
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="!idxEntries?.length">
      <mat-form-field>
        <mat-label>index ID</mat-label>
        <input matInput type="text" [formControl]="indexId" />
        <mat-error
          *ngIf="
            indexId.hasError('maxLength') && (indexId.dirty || indexId.touched)
          "
          >index ID too long</mat-error
        >
        <mat-error
          *ngIf="
            indexId.hasError('pattern') && (indexId.dirty || indexId.touched)
          "
          >invalid index ID</mat-error
        >
      </mat-form-field>
    </div>

    <!-- language -->
    <div *ngIf="langEntries?.length">
      <mat-form-field>
        <mat-label>language</mat-label>
        <mat-select [formControl]="language">
          <mat-option [value]="null">(n/a)</mat-option>
          <mat-option *ngFor="let e of langEntries" [value]="e.id">{{
            e.value
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="!langEntries?.length">
      <mat-form-field>
        <mat-label>language</mat-label>
        <input matInput type="text" [formControl]="language" />
        <mat-error
          *ngIf="
            language.hasError('pattern') && (language.dirty || language.touched)
          "
          >invalid language</mat-error
        >
      </mat-form-field>
    </div>

    <!-- value -->
    <div>
      <mat-form-field>
        <mat-label>value</mat-label>
        <input matInput type="text" [formControl]="value" autofocus />
        <mat-error
          *ngIf="value.hasError('maxLength') && (value.dirty || value.touched)"
          >value too long</mat-error
        >
      </mat-form-field>
    </div>

    <!-- tag -->
    <div>
      <!-- tag (bound) -->
      <mat-form-field *ngIf="tagEntries?.length">
        <mat-label>tag</mat-label>
        <mat-select [formControl]="tag">
          <mat-option *ngFor="let e of tagEntries" [value]="e.id">{{
            e.value
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- tag (free) -->
      <mat-form-field *ngIf="!tagEntries?.length">
        <mat-label>tag</mat-label>
        <input matInput [formControl]="tag" />
        <mat-error
          *ngIf="tag.hasError('maxLength') && (tag.dirty || tag.touched)"
          >tag too long</mat-error
        >
      </mat-form-field>
    </div>

    <!-- note -->
    <div>
      <mat-form-field>
        <mat-label>note</mat-label>
        <input matInput type="text" [formControl]="note" />
        <mat-error
          *ngIf="note.hasError('maxLength') && (note.dirty || note.touched)"
          >note too long</mat-error
        >
      </mat-form-field>
    </div>

    <!-- buttons -->
    <div>
      <button
        type="button"
        mat-icon-button
        color="warn"
        matTooltip="Close keyword"
        (click)="cancel()"
      >
        <mat-icon>cancel</mat-icon>
      </button>
      <button
        type="submit"
        [disabled]="form.invalid || form.pristine"
        mat-icon-button
        color="primary"
        matTooltip="Save keyword"
      >
        <mat-icon>check_circle</mat-icon>
      </button>
    </div>
  </div>
</form>
