<form [formGroup]="form" (submit)="apply()">
  <form [formGroup]="metaForm" (submit)="addMetadatum()">
    <fieldset class="form-row">
      <legend>add filter</legend>

      <!-- metaId (bound) -->
      <mat-form-field *ngIf="entries?.length">
        <mat-label>name</mat-label>
        <mat-select [formControl]="metaId">
          <mat-option *ngFor="let e of entries" [value]="e.id">{{
            e.value
          }}</mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            $any(metaId).errors?.required && (metaId.dirty || metaId.touched)
          "
          >name required</mat-error
        >
      </mat-form-field>
      <!-- metaId (free) -->
      <mat-form-field *ngIf="!entries?.length">
        <mat-label>name</mat-label>
        <input matInput [formControl]="metaId" />
        <mat-error
          *ngIf="
            $any(metaId).errors?.required && (metaId.dirty || metaId.touched)
          "
          >name required</mat-error
        >
        <mat-error
          *ngIf="
            $any(metaId).errors?.maxLength && (metaId.dirty || metaId.touched)
          "
          >name too long</mat-error
        >
      </mat-form-field>

      <!-- metaValue -->
      <mat-form-field>
        <mat-label>value</mat-label>
        <input matInput [formControl]="metaValue" />
        <mat-error
          *ngIf="
            $any(metaValue).errors?.maxLength &&
            (metaValue.dirty || metaValue.touched)
          "
          >value too long</mat-error
        >
      </mat-form-field>

      <button
        type="submit"
        [disabled]="metaForm.invalid"
        mat-icon-button
        color="primary"
      >
        <mat-icon>add_circle</mat-icon>
      </button>
    </fieldset>
  </form>

  <!-- list -->
  <table>
    <thead>
      <tr>
        <th></th>
        <th>name</th>
        <th>value</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let m of metadata.value; let i = index">
        <td class="fit-width">
          <button
            type="button"
            mat-icon-button
            color="warn"
            (click)="deleteMetadatum(i)"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </td>
        <td [matTooltip]="m.id">{{ m.label }}</td>
        <td>{{ m.value }}</td>
      </tr>
    </tbody>
  </table>

  <div class="form-row" style="gap: 0" role="group" aria-label="toolbar">
    <button
      mat-icon-button
      type="button"
      (click)="reset()"
      color="warn"
      matTooltip="Reset filters"
      [disabled]="loading$ | async"
    >
      <mat-icon>clear</mat-icon>
    </button>
    <button
      type="submit"
      mat-icon-button
      color="primary"
      [disabled]="loading$ | async"
      matTooltip="Apply filters"
    >
      <mat-icon>check_circle</mat-icon>
    </button>
  </div>
</form>
