<form [formGroup]="form" (submit)="save()">
  <div id="row">
    <!-- S -->
    <fieldset id="s">
      <legend>subject</legend>
      <!-- picker -->
      <cadmus-ref-lookup
        [service]="lookupService"
        [required]="true"
        [item]="subjectNode.value"
        label="subject"
        (itemChange)="onSubjectChange($event)"
      ></cadmus-ref-lookup>
    </fieldset>
    <!-- P -->
    <fieldset id="p">
      <legend>predicate</legend>
      <!-- picker -->
      <cadmus-ref-lookup
        [service]="lookupService"
        [required]="true"
        [item]="predicateNode.value"
        label="predicate"
        [baseFilter]="{ tag: 'property' }"
        (itemChange)="onPredicateChange($event)"
      ></cadmus-ref-lookup>
    </fieldset>
    <!-- O -->
    <fieldset id="o">
      <legend>object</legend>
      <mat-checkbox [formControl]="isLiteral">literal</mat-checkbox>
      <!-- object -->
      <cadmus-ref-lookup
        *ngIf="!isLiteral?.value"
        [required]="true"
        [service]="lookupService"
        [item]="objectNode.value"
        label="object"
        (itemChange)="onObjectChange($event)"
      ></cadmus-ref-lookup>
      <!-- literal value -->
      <mat-form-field style="width: 100%" *ngIf="isLiteral?.value">
        <mat-label>object</mat-label>
        <textarea matInput [formControl]="literal"></textarea>
        <mat-error
          *ngIf="
            $any(literal).errors?.required && (literal.dirty || literal.touched)
          "
          >literal required</mat-error
        >
        <mat-error
          *ngIf="
            $any(literal).errors?.maxLength &&
            (literal.dirty || literal.touched)
          "
          >literal too long</mat-error
        >
      </mat-form-field>
      <div class="form-row">
        <!-- literal language -->
        <mat-form-field *ngIf="isLiteral?.value">
          <mat-label>language</mat-label>
          <input matInput [formControl]="literalLang" />
          <mat-hint>ISO-639 etc.</mat-hint>
          <mat-error
            *ngIf="
              $any(literalLang).errors?.maxLength &&
              (literalLang.dirty || literalLang.touched)
            "
            >language too long</mat-error
          >
        </mat-form-field>
        <!-- literal type -->
        <mat-form-field *ngIf="isLiteral?.value">
          <mat-label>type</mat-label>
          <input matInput [formControl]="literalType" />
          <mat-hint>XML data type (xs:...)</mat-hint>
          <mat-error
            *ngIf="
              $any(literalType).errors?.maxLength &&
              (literalType.dirty || literalType.touched)
            "
            >type too long</mat-error
          >
        </mat-form-field>
      </div>
    </fieldset>
  </div>

  <!-- buttons -->
  <div>
    <button color="warn" mat-icon-button type="button" (click)="cancel()">
      <mat-icon>cancel</mat-icon>
    </button>
    <button
      color="primary"
      mat-icon-button
      type="submit"
      [disabled]="form.invalid"
    >
      <mat-icon>check_circle</mat-icon>
    </button>
  </div>
</form>
