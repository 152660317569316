<div class="form-row">
  <!-- provider -->
  <mat-form-field *ngIf="configs?.length">
    <mat-label>provider</mat-label>
    <mat-select [formControl]="config">
      <mat-option *ngFor="let c of configs" [value]="c">
        <div class="form-row">
          <img
            *ngIf="c.iconUrl"
            alt="{{ c.name }}"
            [src]="c.iconUrl"
            [width]="iconSize.width"
            [height]="iconSize.height"
          />
          <span>{{ c.name }}</span>
        </div>
      </mat-option>
    </mat-select>
    <mat-hint>{{ config.value?.description }}</mat-hint>
    <mat-error
      *ngIf="$any(config).errors?.required && (config.dirty || config.touched)"
      >config required</mat-error
    >
  </mat-form-field>

  <!-- lookup -->
  <cadmus-ref-lookup
    *ngIf="config.value"
    [service]="config.value.service"
    [item]="config.value.item"
    [required]="config.value.required"
    [hasMore]="config.value.hasMore"
    [optDialog]="config.value.optDialog"
    [options]="config.value.options"
    [linkTemplate]="config.value.linkTemplate"
    [label]="config.value.label"
    (itemChange)="onItemChange($event)"
    (moreRequest)="onMoreRequest($event)"
  ></cadmus-ref-lookup>
</div>
