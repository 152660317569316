<form [formGroup]="form" (submit)="save()">
  <!-- uri (readonly if not new) -->
  <div class="form-row">
    <mat-form-field *ngIf="isNew">
      <mat-label>uri</mat-label>
      <input matInput [formControl]="uri" />
      <mat-error
        *ngIf="$any(uri).errors?.required && (uri.dirty || uri.touched)"
        >uri required</mat-error
      >
      <mat-error
        *ngIf="$any(uri).errors?.maxLength && (uri.dirty || uri.touched)"
        >uri too long</mat-error
      >
    </mat-form-field>
    <span style="color: silver" *ngIf="!isNew">{{ uri.value }} </span>

    <!-- label -->
    <mat-form-field>
      <mat-label>label</mat-label>
      <input matInput [formControl]="label" />
      <mat-error
        *ngIf="$any(label).errors?.required && (label.dirty || label.touched)"
        >label required</mat-error
      >
      <mat-error
        *ngIf="$any(label).errors?.maxLength && (label.dirty || label.touched)"
        >label too long</mat-error
      >
    </mat-form-field>

    <!-- class -->
    <mat-checkbox [formControl]="isClass">is class</mat-checkbox>

    <!-- tag -->
    <mat-form-field *ngIf="tagEntries?.length" style="width: 8em">
      <mat-label>tag</mat-label>
      <mat-select [formControl]="tag">
        <mat-option [value]="null">(no tag)</mat-option>
        <mat-option *ngFor="let e of tagEntries" [value]="e.id">{{
          e.value
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="!tagEntries?.length">
      <mat-label>tag</mat-label>
      <input matInput [formControl]="tag" />
      <mat-error
        *ngIf="$any(tag).errors?.maxLength && (tag.dirty || tag.touched)"
        >tag too long</mat-error
      >
    </mat-form-field>
  </div>

  <!-- buttons -->
  <div>
    <button color="warn" mat-icon-button type="button" (click)="cancel()">
      <mat-icon>cancel</mat-icon>
    </button>
    &nbsp;
    <button
      color="primary"
      mat-icon-button
      type="submit"
      [disabled]="form.invalid"
    >
      <mat-icon>check_circle</mat-icon>
    </button>
  </div>
</form>
