import {
  AbstractControl,
  FormGroup,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export class PasswordValidators {
  /** "Standard" password validator for my API services. */
  public static standard(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      if (control.value.length < 8) {
        return {
          passwordtooshort: true,
        };
      }

      if (!/.*[A-Z].*/.test(control.value)) {
        return {
          noupperinpassword: true,
        };
      }

      if (!/.*[a-z].*/.test(control.value)) {
        return {
          nolowerinpassword: true,
        };
      }

      if (!/.*[A-Z].*/.test(control.value)) {
        return {
          noupperinpassword: true,
        };
      }

      if (!/.*[-`~!@#$%^&*()_+=\[\]{};:'",.<>/?|\\].*/.test(control.value)) {
        return {
          nosymbolinpassword: true,
        };
      }

      return null;
    };
  }

  public static areEqual(
    controlName: string,
    otherControlName: string
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const g = control as FormGroup | UntypedFormGroup;
      const a = g.controls[controlName];
      const b = g.controls[otherControlName];
      return a.value !== b.value
        ? {
            areequal: true,
          }
        : null;
    };
  }
}
