<form [formGroup]="form" (submit)="save()">
  <div>
    <div class="form-row">
      <!-- tag (bound) -->
      <mat-form-field *ngIf="idTagEntries?.length" style="width: 8em">
        <mat-label>tag</mat-label>
        <mat-select [formControl]="tag">
          <mat-option *ngFor="let e of idTagEntries" [value]="e.id">{{
            e.value
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- tag (free) -->
      <mat-form-field *ngIf="!idTagEntries?.length" style="width: 8em">
        <mat-label>tag</mat-label>
        <input matInput [formControl]="tag" />
        <mat-error *ngIf="tag.errors?.maxLength && (tag.dirty || tag.touched)"
          >tag too long</mat-error
        >
      </mat-form-field>

      <!-- scope (bound) -->
      <mat-form-field *ngIf="idScopeEntries?.length" style="width: 8em">
        <mat-label>scope</mat-label>
        <mat-select [formControl]="scope">
          <mat-option *ngFor="let e of idScopeEntries" [value]="e.id">{{
            e.value
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- scope (free) -->
      <mat-form-field *ngIf="!idScopeEntries?.length" style="width: 8em">
        <mat-label>scope</mat-label>
        <input matInput [formControl]="scope" />
        <mat-error
          *ngIf="scope.errors?.maxLength && (scope.dirty || scope.touched)"
          >scope too long</mat-error
        >
      </mat-form-field>

      <!-- value -->
      <mat-form-field>
        <mat-label>value</mat-label>
        <input matInput [formControl]="value" />
        <mat-error
          *ngIf="value.errors?.required && (value.dirty || value.touched)"
          >value required</mat-error
        >
        <mat-error
          *ngIf="value.errors?.maxLength && (value.dirty || value.touched)"
          >value too long</mat-error
        >
      </mat-form-field>

      <!-- lookup -->
      <div *ngIf="!noEidLookup" style="margin-top: 4px">
        <mat-expansion-panel [(expanded)]="lookupExpanded">
          <mat-expansion-panel-header>lookup</mat-expansion-panel-header>
          <cadmus-scoped-pin-lookup
            (idPick)="onIdPick($event)"
          ></cadmus-scoped-pin-lookup>
        </mat-expansion-panel>
      </div>
    </div>

    <!-- assertion -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>assertion</mat-expansion-panel-header>
      <cadmus-refs-assertion
        [assTagEntries]="assTagEntries"
        [refTypeEntries]="refTypeEntries"
        [refTagEntries]="refTagEntries"
        [assertion]="assertion.value"
        (assertionChange)="onAssertionChange($event)"
      >
      </cadmus-refs-assertion>
    </mat-expansion-panel>
  </div>

  <!-- buttons -->
  <div *ngIf="hasSubmit">
    <button mat-icon-button color="warn" type="button" (click)="cancel()">
      <mat-icon>close</mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      type="submit"
      [disabled]="form.invalid"
    >
      <mat-icon>check_circle</mat-icon>
    </button>
  </div>
</form>
