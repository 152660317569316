<form [formGroup]="form" (submit)="save()">
  <mat-card appearance="outlined">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>textsms</mat-icon>
      </div>
      <mat-card-title
        >Witnesses Fragment {{ data?.value?.location }}</mat-card-title
      >
      <mat-card-subtitle>
        {{ frText }}
      </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <button
        type="button"
        mat-flat-button
        color="primary"
        matTooltip="Add a new witness"
        (click)="openCurrentWitness()"
      >
        <mat-icon>add_circle</mat-icon> witness
      </button>
      <table *ngIf="witnesses.valid">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th>id</th>
            <th>cit.</th>
            <th>text</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let w of witnesses?.value;
              let i = index;
              let first = first;
              let last = last
            "
          >
            <td>
              <button
                type="button"
                mat-icon-button
                matTooltip="Edit this witness"
                (click)="openCurrentWitness(w)"
              >
                <mat-icon color="primary">edit</mat-icon>
              </button>
            </td>
            <td>
              <button
                type="button"
                mat-icon-button
                matTooltip="Move this witness up"
                (click)="moveWitnessUp(i)"
                [disabled]="first"
              >
                <mat-icon>arrow_upwards</mat-icon>
              </button>
            </td>
            <td>
              <button
                type="button"
                mat-icon-button
                matTooltip="Move this witness down"
                (click)="moveWitnessDown(i)"
                [disabled]="last"
              >
                <mat-icon>arrow_downwards</mat-icon>
              </button>
            </td>
            <td>
              <button
                type="button"
                mat-icon-button
                matTooltip="Remove this witness"
                (click)="deleteWitness(i)"
              >
                <mat-icon color="warn">remove_circle</mat-icon>
              </button>
            </td>
            <td>
              {{ w.id }}
            </td>
            <td>
              {{ w.citation }}
            </td>
            <td>
              {{ w.text }}
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <div *ngIf="currentWitnessOpen" [@slideInOut]>
        <form [formGroup]="witness" (submit)="saveCurrentWitness()">
          <!-- id -->
          <div>
            <mat-form-field>
              <mat-label>ID</mat-label>
              <input
                type="text"
                matInput
                formControlName="id"
                spellcheck="false"
              />
              <mat-error
                *ngIf="id.hasError('required') && (id.dirty || id.touched)"
                >ID required</mat-error
              >
              <mat-error
                *ngIf="id.hasError('maxLength') && (id.dirty || id.touched)"
                >ID too long</mat-error
              >
            </mat-form-field>
          </div>
          <!-- citation -->
          <div>
            <mat-form-field>
              <mat-label>citation</mat-label>
              <input
                type="text"
                matInput
                formControlName="citation"
                spellcheck="false"
              />
              <mat-error
                *ngIf="
                  citation.hasError('required') &&
                  (citation.dirty || citation.touched)
                "
                >citation required</mat-error
              >
              <mat-error
                *ngIf="
                  citation.hasError('maxLength') &&
                  (citation.dirty || citation.touched)
                "
                >citation too long</mat-error
              >
            </mat-form-field>
          </div>
          <!-- text -->
          <div>
            <h3>Text</h3>
            <ngx-monaco-editor
              [options]="editorOptions"
              formControlName="text"
            ></ngx-monaco-editor>
            <mat-error
              *ngIf="text.hasError('required') && (text.touched || text.dirty)"
              >please enter some text</mat-error
            >
            <hr />
            <markdown
              *ngIf="text?.value"
              [data]="text?.value || undefined"
            ></markdown>
          </div>
          <!-- note -->
          <div>
            <h3>Note</h3>
            <ngx-monaco-editor
              [options]="editorOptions"
              formControlName="note"
            ></ngx-monaco-editor>
            <hr />
            <markdown
              *ngIf="note?.value"
              [data]="note?.value || undefined"
            ></markdown>
          </div>
          <button
            type="button"
            mat-icon-button
            color="warn"
            matTooltip="Close this witness"
            (click)="closeCurrentWitness()"
          >
            <mat-icon>clear</mat-icon>
          </button>
          <button
            type="submit"
            color="primary"
            mat-icon-button
            matTooltip="Save this witness"
          >
            <mat-icon>check_circle</mat-icon>
          </button>
        </form>
      </div>
    </mat-card-content>

    <mat-card-actions>
      <cadmus-close-save-buttons
        [form]="form"
        [noSave]="userLevel < 2"
        (closeRequest)="close()"
      ></cadmus-close-save-buttons>
    </mat-card-actions>
  </mat-card>
</form>
